import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, media } from '../../constants/screens';
import { DARK_GRAY, LIGHT_GRAY_2 } from '../../constants/colors';

const PolicyContent = (props) => {
  const { data } = props;

  return (
    <Root>
      <Content source={data} />
    </Root>
  );
};

PolicyContent.propTypes = {
  data: PropTypes.string.isRequired,
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px 0 0 0;
`;

const Content = styled(ReactMarkdown)`
  max-width:  ${CONTAINER_WIDTH};
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  h2 {
    font-size: 32px;
  }
  
  h4 {
    font-size: 22px;
    margin: 20px 0;
  }
  
  h6 {
    margin: 20px 0;
  }
  
  p {
    line-height: 20px;
    text-align: justify;
    margin: 20px 0;
  }
  
  ol {
    list-style: decimal inside;
    
    p {
      display: inline;
    }
  }
  
  ul {
    list-style: disc inside;
    
     p {
      display: inline;
    }
  }
  
  li {
    margin: 20px 0;
  }
  
  a {
    text-decoration: none;
  }
  
  code {
    background-color: ${LIGHT_GRAY_2};
    font-size: 16px;
    padding: 5px;
    border: 1px solid ${DARK_GRAY};
  }
`;

export default PolicyContent;
