import React from 'react';
import { compose, pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import withCustomLayout from '../enhancers/withCustomLayout';
import { DARK_SCHEME } from '../constants/colors';
import Content from '../components/policy/content';
import withStickyHeader from '../enhancers/withStickyHeader';

const PrivacyPolicyPage = ({ data }) => (
  <div>
    <Content data={pathOr('', ['page', 'sections', 0, 'content', 0, 'description', 'md'], data)} />
  </div>
);

PrivacyPolicyPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default compose(
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(PrivacyPolicyPage);

// eslint-disable-next-line
export const query = graphql`
  query PrivacyPolicyPageQuery {
    page(url: { regex: "/privacy-policy/" }) {
      sections {
        content {
          description {
            md
          }
        }
      }
    }
  }
`;
